import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiResponseModel, IUser } from "src/app/models/common.model";
import { AccountType, BuisnessType } from "src/app/models/loans";
import { AppConfigState } from "src/app/store/app.states";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {}

  getBuisnessTypes() {
    return this.http.get<ApiResponseModel<BuisnessType[]>>(this.baseUrl + "api/Common/BusinessType");
  }

  GetAppConfig() {
    return this.http.get<AppConfigState>("assets/app.config.json");
  }

  getUserById(userId: string) {
    return this.http.get<ApiResponseModel<IUser>>(this.baseUrl + "api/User/" + userId);
  }

  getAccountTypes() {
    return this.http.get<ApiResponseModel<AccountType[]>>(this.baseUrl + "api/Common/AccountType");
  }
}
