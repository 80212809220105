import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiResponseModel } from "src/app/models/common.model";
import {
  IncludeExcludeLoanTransaction,
  TransactionDetails,
  TransactionStatementPeriodDetails,
} from "src/app/store/transaction/transaction.types";

@Injectable({
  providedIn: "root",
})
export class StatementTransactionServiceService {
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {}

  getTransactionDetails(loanId: string, summaryId: string) {
    return this.http.get<ApiResponseModel<TransactionDetails[]>>(
      this.baseUrl + "api/StatementTransaction/Details/" + loanId + "/" + summaryId
    );
  }

  getStatementSummaryDetails(loanId: string) {
    return this.http.get<ApiResponseModel<TransactionStatementPeriodDetails[]>>(
      this.baseUrl + "api/StatementSummary/Details/" + loanId
    );
  }

  saveValidatetransactions(transactions: TransactionDetails[], flag: boolean) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const StatementTransactions: TransactionDetails[] = transactions;
    return this.http.post(
      this.baseUrl + "api/StatementSummary/SaveValidateStatementTransaction?isValidated=" + flag,
      StatementTransactions,
      { headers, responseType: "text" }
    );
  }

  saveOrValidateTransactionDetails(transactionDetails: TransactionDetails[], isValidated: boolean, loanId: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<ApiResponseModel<TransactionDetails[]>>(
      this.baseUrl +
        "api/StatementSummary/SaveValidateStatementTransaction?loanId=" +
        loanId +
        "&isValidated=" +
        isValidated,
      transactionDetails,
      { headers }
    );
  }

  reValidateSummary(statementId: string, isValidate: boolean) {
    return this.http.put<ApiResponseModel<string>>(this.baseUrl + `api/StatementSummary/ReValidateSummary`, {
      statementId,
      isValidate,
    });
  }

  processLoanTransactionFlags(loanId: string) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/ProcessTransactionFlags/${loanId}`,
      {},
      { headers }
    );
  }

  processLoanCategory(loanId: string) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/ProcessCategory/${loanId}`,
      {},
      { headers }
    );
  }

  includeExcludeForIncomeCalculation(payload: IncludeExcludeLoanTransaction) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/StatementTransaction/IncludeExcludeForIncomeCalculation`,
      payload,
      { headers }
    );
  }

  resetIncludeExcludeForIncomeCalculation(payload: IncludeExcludeLoanTransaction) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/StatementTransaction/ResetIncludeExcludeForIncomeCalculation`,
      payload,
      { headers }
    );
  }
}
